























































































import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import {
    apiStorehouseDel,
    apiStorehouseStatus, // 修改仓库状态
    apiStorehouseList // 仓库列表
} from '@/api/delivery'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class Category extends Vue {
    pager = new RequestPaging()
    apiStorehouseList = apiStorehouseList
    getList(page?: number): void {
        console.log(this.pager)
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiStorehouseList,
            params: {}
        })
    }
   


    created() {
        this.getList()
    }
}
